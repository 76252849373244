import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Lottie from 'react-lottie';
import { Link } from 'gatsby';
import { home } from 'react-icons-kit/entypo/home';

import Box from '../../elements/Box';
import Button from '../../elements/Button';
import animationData from './errorAnimation.json';
import { ErrorWrapper, ErrorContent, ButtonWrapper } from './error.style';

const ErrorSec = ({ imageWrapper, homeButton, errorMessage }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  return (
    <ErrorWrapper>
      <ErrorContent>
        <Box {...imageWrapper} className="image-wrapper">
          <Lottie options={defaultOptions} height={350} width={350} />
        </Box>
        <ButtonWrapper>
          <Link href="/">
            <Button
              {...homeButton}
              title={errorMessage}
              icon={<Icon icon={home} size={24} />}
              className="domain_search_button"
            />
          </Link>
        </ButtonWrapper>
      </ErrorContent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px'],
  },
  homeButton: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#0f2137',
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
    bg: '#e2e7f0',
    fontFamily: 'lato',
  },
};

export default ErrorSec;
