import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from '../assets/css/style';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <ErrorSec errorMessage='Zu Homepage'></ErrorSec>
   </>
  );
}

export default NotFoundPage;
